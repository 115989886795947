
.col-cabecera {
  display: flex;
  box-shadow: 0px 1px 1px 0px rgb(167, 167, 233); 
  transition:0,4s;
  text-align: center;
  background-color: #028d7b;
}
.logo1 {
    width: 45px;
}

.logo1 {
    animation-iteration-count: infinite;
    animation-duration: 20s;
    transform-style: preserve-3d;
    animation-name: slidein;
  }
  
  @keyframes slidein {
    from {
        transform: rotateY(-360deg);

    }
  
    to {
        transform: rotateY(360deg);
    }
  }
  
.usu-cabecera{
    margin-top: 10px;
    font-size: 30px;
    color: #fff;
}
@keyframes anim{
  0% {background-color: #ff0;} /*Amarillo*/
 25% {background-color: #f80;} /*Naranja*/
 50% {background-color: #000;} /*Negro*/
 75% {background-color: #f80;} /*Otra vez naranja*/
100% {background-color: #ff0;} /*Otra vez amarillo*/
}
.mensaje-cabecera1{
  margin-right: 30px;
  height: 30px;
  width: 30px;
  /* margin-top: 10px; */
  cursor:pointer;
  animation-name: anim;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}
.mensaje-cabecera{
  margin-right: 30px;
  height: 30px;
  width: 30px;
  /* margin-top: 10px; */
  cursor:pointer;
}
.cflotador{
  margin-top: 50px;
  position: absolute;
  height: 70%;
  width: 200px;
  align-Content: center;
  justify-Content: center;
  z-index:50;
  /* background-color:  #2bbbad; */
}

.cflotador1{
  align-Self: flex-start;
  width: 100%;
  z-index:50;
}