h1{
    text-align: center;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
  }
   
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  
}
.cont-footer-princ {
 left: 30px !important; 
}

.contenedor-pago1 {
  width: 100%;
  height: 550px;
  background-color: #e7f3ce
}

.contenedor-cedula  {
    width: 100%;
    margin: 0px;
    
  }
  .contenedor-titulo {
    display: contents;
    height:200px;
  }
  .titulo-pago {
    line-height: 50px;
    background-color: #028d7b;
    font-size: 15px;
    text-align: center;
    color: #050505;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 15px;


  }

  .labelcedulac  {
    font-size: 18px;
    color: #050505;
    font-weight: bold;
    
  }
  .contenedor-cedula, .contenedorcedula1, .contenedorcedula2, .cont-radio {
    text-align: center;
      display: inline-block;
  }
  
  .contenedorcedula1{
      margin-top: 20px;
  }
  .labelcedulac1  {
    font-size: 18px;
    color: #050505;
    font-weight: bold;
    margin-right: 20px;
    
  }
  .opnivel2 {
    font-size: 20px;
    margin-left: 20px;
  }

  .boton {
    color: #070707;
    background-color: #fff;
    outline: none;
    border: solid 2px#C8C8C8;
    font-size: 16px;
    padding: 5px 5px;
    border-radius: 10px; 
    font-weight: bold;
    margin-bottom: 5%;

  }
.boton:hover {
  color:  #fff;
  background-color: rgb(35, 80, 69)

}
.titucxc11, .titucxc22, .titucxc33, .titucxc44  {
  background-color: #2bbbad ;
  color: #fff;
  font-weight: bold;
  height: 10px;
}
.titucxc11 {
  text-align: center;
  width: 10px;
  font-size: 15px;
}
.titucxc22 {
  text-align: center;
  width: 40px;
  font-size: 15px;
}
.titucxc33 {
  text-align: center;
  width: 200px;
  font-size: 15px;
}
.totalescxc11, .totalescxc22, .totalescxc33 {
  text-align: left;
  font-size: 12px;
  width: 40px;
}
.totalescxc44 {
  text-align: right;
  font-size: 12px;
}
.totalescxc2{
color: red;
font-weight: bold;
}
#codigo1 {
  width: 50px;
  height: 35px;
  margin-right: 25px;
  font-size: 18px;
}

#identificacion1{
  width: 250px;
}

.flotador1xxC {
    margin-top: 0px;
    position: absolute;
    height: 80%;
    width: 90%;
    align-Content: center;
    justify-Content: center;
    z-index: 10;
    background-color: #2bbbad;

}
.contenedorp {
  display: grid;
  justify-content: center;
}
.titulo-planillai {
  line-height: 50px;
  background-color: #028d7b;
  font-size: 15px;
  text-align: center;
  color: #050505;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 15px;
}
.fechas {
  text-align: center !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.contenedor-cedula, .xcontenedorcedula1, .contenedorcedula2, .cont-radio {
  text-align: center;
    display: inline-block;
}
.xcontenedorcedula1{
    margin-top: 50px;
}
