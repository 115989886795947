.cerrarme4{
    background-color: #6aaeec;
    background-image: url('../../imagenes/editor.png');
    background-size: 20px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block;
    margin-top: 2px;
  }
  .cerrarme5{
    background-color: #6aaeec;
    background-image: url('../../imagenes/editor.png');
    background-size: 20px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block;
    cursor: pointer;
    margin-top: 2px;
  }
  .opcionno{
      margin-top: 10px;
      width: 100px;
  }
  #ordendellegada{
      height: 25px;
      width: 50px;
      margin: 0;
      padding: 0;
      text-align: left;    
  }
  .cont-fechano {
        margin-top: 10px;
        margin: 0;
}
  #fechaesperano{
      height: 25px;
      margin:0;
  }
  .cont-motivo-no{
      text-align: left;
  }

  .cont-listmedix {
        color: rgb(167, 167, 167);
        top: 40px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 80% !important;
        justify-content: center;
  }
  .listcxc {
    background-color: #fff;
    height: 300px;
    width: auto;
    overflow: auto;
  }
  

  .listcxcx {
    background-color: #e7f3ce;
    height: 240px;
    width: auto;
    overflow: auto;
  }

 
  .tablacxct {
  color: #fff;
  background-color: #fff;
  width: 100%;
  border: 1px solid #2ccbdd;
  font-size: 16px;
  border-collapse: collapse;
  caption-side: top;
  border-collapse: collapse;
  height: 20px;
}

  /* .tablacxcx{
    color: #fff;
    background-color: #fff;
    width: 100%;
    border: 1px solid #7ac5cd;
    font-size: 16px;
    border-collapse: collapse;
    
    caption-side: top;
    border-collapse: collapse;  
    height: 20px;
} */
  .xcontenedorcedula1x {
    text-align: center;
    display: inline-block;
    margin-top: 10px !important;

  }
  .titucxc3x{
      width: 5px !important;
      text-align: center !important;
  }
.totalescxcx {
    cursor: context-menu !important;
    text-align: left !important;
    font-size: 12px !important;
}
.contenedorcedula1x {
    width: 700px !important;
}
#idmateria, #idfechaPlani{
  font-size: 12px;
}
#fechaDesde, #fechaHasta{
  font-size: 10px;
}
.contenedorcedula1f{
  /* margin-bottom: 5px;
  margin-top: 5px !important; */
  text-align: center;
  display: inline-block;
}
.botones{
  margin-top: 10px;
  text-align: center;  
}
.listadopc{
  background-color: #028d7b;
  color: #fff;
}
.desde, .hasta{
  margin-top: 15px;
}


.cont-listadopc {
  color: rgb(7, 5, 5);
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}

.listadopc {
  height: 380px;
  width: 100%;
  font-size: 12px;
  width: auto;
  overflow: auto;
}

.tabla2pc {
  color: #fff;
  background-color: #7ac5cd;
  width: 100%;
  border: 1px solid #7ac5cd;
  font-size: 15px;
  text-align: center;
  border-collapse: collapse;
  margin: 0 0 1em 0;
  caption-side: top;
}

.cuerpo{
    cursor: pointer;
}

.cuerpo1{
  cursor: pointer;
}

.tituevo{
  color: #fff;
  background-color: #028d7b;
  text-align: left;
  padding: 10px;

}

.titucxc4{
  width: 50px !important;
  text-align: center !important;
  background-color: #2bbbad;
}

.opcionev{
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.odd{
  background-color: #11a794;
}
.odd1{
  background-color: #5ed4c5;
}
.botonesCon{
  margin-top: 5px;
}
#montoB, #debe, #haber, #saldo{
  margin-top: -5px;
}
.verde{
  color: #075c04;
}
.azul{
  color: #0249a5;
}
.morado{
  color: #080306;
}
.rojo{
  color: #ca0610;
}
.flexContainer { 
  padding: 0%;
  height: 20%;
  margin: 2px 10px;
  display: flex;
} 

.izquierda {
  flex-basis : 20%;
}

.derecha {
  flex-basis : 75%;
}
.radio {
  margin-left: 10% !important;
  width: 150px;
  margin-bottom: -10px;
}
.container {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.opcedulaP {
  margin-right: 1px;   
  display: inline;
  padding: 5px;
  width: 10px;
  margin: 10px 0;
  font-size: 15px;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
}
.container:hover input ~ .checkmark {
  background-color: #ccc;
}
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.container input:checked ~ .checkmark:after {
  display: block;
}
.boton {
  color: #070707;
  background-color: #fff;
  outline: none;
  border: solid 2px#C8C8C8;
  font-size: 10px !important;
  padding: 5px 5px;
  border-radius: 10px; 
  font-weight: bold;
  margin-bottom: 10px !important;

}
.boton:hover {
color:  #fff;
background-color: rgb(35, 80, 69)

}
.opvacunacionsi{
  height: 15px !important;
  width: 20px !important;
  margin-right: 1px;   
    display: inline;
    padding: 5px;
    width: 10px;
    margin: 10px 0;
    font-size: 15px;
}

#banco{
  height: 35px;
  margin-top: 12px;
  margin-bottom: 20px;
}
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
#codigo3 {
  width: 150px;
  height: 35px;
  margin-right: 25px;
  font-size: 18px;
}

#codigo4 {
  width: 150px;
  height: 35px;
  font-size: 20px;
}

#codigo7 {
  width: 150px;
  height: 35px;
  margin-right: 25px;
  font-size: 18px;
}

#codigo8 {
  width: 150px;
  height: 35px;
  font-size: 20px;
}


.titucxc1,
.titucxc2,
.titucxc3 {
  background-color: #2bbbad;
  color: #fff;
  font-weight: bold;
  height: 40px;
 
}
.titucxc1 {
  text-align: center;
  width: 325px !important;
  width: 130px;
}
.titucxc2 {
  text-align: center;
  width: 500px;
}
.titucxc3 {
  text-align: center;
  width: 10px;
}
.totalescxc {
  text-align: center;
}
.renglon {
  height: 30px;
}
.totalescxc1, .totalescxc2, .totalescxc3 {
  text-align: left!important;
  font-size: 15px!important;
  color: #fff !important;
}
.totalescxcx {
  cursor: context-menu!important;
  text-align: left!important;
  font-size: 12px!important;
  height: 30px!important;

}

#tabla {
  cursor: pointer;
}

.odd {
  background-color: #11a794;
}

.theadcreartabla tr th  { 
  position: sticky;
  top: 0;
  z-index: 1200;
  background-color:  #028d7b;

}

.table-responsive { 
  height:200px;
  overflow:scroll;
} 